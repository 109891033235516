import {useEffect, useState} from "react";

function PolyfillGyro() {
  this.addEventListener = () => {};
  this.removeEventListener = () => {};
  this.start = () => {};
}

const GyroscopeComponent = (props) => {
  const [permissions, setPermissions] = useState(null);
  const [gyro, setGyro] = useState(null);
  const [coordinates, setCoordinates] = useState({
    x: '0',
    y: '0',
    z: '0'
  });

  useEffect(() => {

    window.navigator.permissions.query({name: 'gyroscope'}).then((result) => {
      console.log(result);
      if (result.state === 'granted') {
        window.Gyroscope = window.Gyroscope || PolyfillGyro;
        const gyro = new window.Gyroscope({frequency: 4});
        setGyro(gyro);

        const listener = (e) => {
          console.log('listener');
          console.log("Angular velocity along the X-axis " + gyro.x);
          console.log("Angular velocity along the Y-axis " + gyro.y);
          console.log("Angular velocity along the Z-axis " + gyro.z);
          setCoordinates({
            x: ('' + gyro.x).slice(0, 5),
            y: ('' + gyro.y).slice(0, 5),
            z: ('' + gyro.z).slice(0, 5)
          });
        }

        gyro.addEventListener('reading', listener);
        gyro.start();
      }

      setPermissions(result.state);

      result.onchange = (result) => {
        setPermissions(result.state);
      }

    });


  }, []);

  return (
    <div>
      <p>Gyroscope</p>
      <p>{coordinates.x} {coordinates.y} {coordinates.z}</p>
      <p>{permissions}</p>
    </div>
  )
}

export default GyroscopeComponent;